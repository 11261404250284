footer {
  padding-top: 0;
  text-align: center;
  opacity: 0.3;

  a,
  a:visited {
    color: var(--color-text);
  }
}
