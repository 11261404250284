*, :before, :after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

:root {
  --bodyBG: #fff;
  --bodyFontColor: #232;
  --bodyFontSize: 16px;
  --bodyFontFamily: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  --bodyFontWeight: 400;
  --bp-small: 768px;
  --bp-medium: 1024px;
  --bp-large: 1280px;
  --line-height: 1.5;
  --linkFontColor: #284162;
  --linkFontColorHover: #0535d2;
  --highlight: #0000000d;
  --siteWidth: 300px;
  --input1: #e03c31;
  --input2: #ff7f41;
  --input3: #f7ea48;
  --input4: #2dc84d;
  --input5: #147bd1;
  --input6: #753bbd;
}

@media (prefers-color-scheme: dark) {
  :root {
    --bodyBG: #2e222f;
    --bodyFontColor: #fff;
    --highlight: #ffffff0d;
  }
}

body {
  color: var(--bodyFontColor);
  background-color: var(--bodyBG);
  font-family: var(--bodyFontFamily);
  font-size: var(--bodyFontSize);
  font-weight: var(--bodyFontWeight);
  line-height: var(--line-height);
  height: 100vh;
  flex-direction: column;
  display: flex;
}

footer, header, main {
  max-width: calc(var(--siteWidth)  + 2rem);
  margin: 0 auto;
  padding: 3rem 1rem;
}

@media only screen and (max-width: 768px) {
  footer, header, main {
    padding: 1.5rem 1rem;
  }
}

a, a:visited {
  color: var(--linkFontColor);
}

a:hover, a:visited:hover {
  color: var(--linkFontColorHover);
}

p {
  margin: .75rem 0;
  padding: 0;
}

button {
  color: var(--bodyFontColor);
  font-size: 1rem;
}

main {
  max-height: 100vh;
  flex-direction: column;
  justify-content: space-between;
  padding: 0 0 1.5rem;
  display: flex;
}

@media only screen and (max-width: 768px) {
  main {
    height: 90vh;
  }
}

.hidden {
  pointer-events: none;
  display: none;
}

hr {
  opacity: .5;
  margin: 1.25rem 0;
}

game-field {
  width: 100%;
  height: 70%;
  padding: 1rem 0 2.5rem;
}

game-field ul {
  scroll-snap-type: y mandatory;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
  max-height: 65vh;
  font-size: 1.5rem;
  list-style: none;
  overflow-y: scroll;
}

@media only screen and (max-width: 768px) {
  game-field ul {
    height: 100%;
    max-height: none;
  }
}

game-field li {
  width: 100%;
  scroll-snap-align: start;
  align-items: center;
  padding-top: 15px;
  display: flex;
  position: relative;
}

game-field li div {
  background-color: var(--highlight);
  height: 42px;
  width: 100%;
  border-radius: 5px;
  margin: 0 0 .75rem;
  padding: 5px 0;
  display: flex;
  position: relative;
}

game-field .input {
  width: 25%;
  max-width: 66.666px;
  height: 32px;
  border-radius: 5px;
  margin: 0 7.665px;
  position: relative;
}

game-field .input:after {
  content: "";
  width: 18px;
  height: 18px;
  border-radius: 50%;
  display: block;
  position: absolute;
  top: -9px;
  right: -9px;
}

game-field .input--1 {
  background-color: var(--input1);
}

game-field .input--2 {
  background-color: var(--input2);
}

game-field .input--3 {
  background-color: var(--input3);
}

game-field .input--4 {
  background-color: var(--input4);
}

game-field .input--5 {
  background-color: var(--input5);
}

game-field .input--6 {
  background-color: var(--input6);
}

game-field .icon {
  height: 10px;
  justify-content: center;
  align-items: center;
  font-size: .75rem;
  display: flex;
  position: absolute;
  top: 0;
}

game-field .icon:after {
  content: "";
  width: 10px;
  height: 10px;
  border: 1px solid var(--highlight);
  border-radius: 50%;
  display: block;
  position: absolute;
  top: 0;
  left: -15px;
}

game-field .icon--correct {
  right: 42px;
}

game-field .icon--correct:after {
  background-color: #6abd6a;
}

game-field .icon--wrong {
  right: 12px;
}

game-field .icon--wrong:after {
  background-color: #ed060a;
}

input-field {
  touch-action: manipulation;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 0;
  display: flex;
}

input-field button {
  cursor: pointer;
  width: 40px;
  height: 55px;
  background: none;
  border: none;
  border-radius: 5px;
  margin: 0 5px;
  font-size: 2rem;
  font-weight: 600;
  transition: opacity .3s ease-in-out;
}

input-field button[disabled="true"] {
  opacity: .3;
}

input-field button:hover {
  opacity: .7;
}

input-field button[data-input="1"] {
  background-color: var(--input1);
}

input-field button[data-input="2"] {
  background-color: var(--input2);
}

input-field button[data-input="3"] {
  background-color: var(--input3);
}

input-field button[data-input="4"] {
  background-color: var(--input4);
}

input-field button[data-input="5"] {
  background-color: var(--input5);
}

input-field button[data-input="6"] {
  background-color: var(--input6);
}

input-field button[data-submit] {
  background-color: var(--highlight);
  width: calc(60% - 10px);
  margin-top: 1.25rem;
  margin-right: 5px;
  font-size: 1rem;
}

input-field button[data-reset] {
  background-color: var(--highlight);
  width: calc(40% - 10px);
  margin-top: 1.25rem;
  margin-left: 5px;
  font-size: 1rem;
}

pop-up {
  z-index: 1000;
  width: 100vw;
  height: 100vh;
  background-color: #00000080;
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
}

.popup-container {
  width: 100%;
  max-width: 600px;
  background-color: var(--bodyBG);
  border-radius: 5px;
  padding: 1.5rem;
  position: absolute;
  overflow: hidden;
  box-shadow: 0 0 10px #00000080;
}

.popup-container a, .popup-container a:visited {
  color: var(--bodyFontColor);
}

@media only screen and (max-width: 768px) {
  .popup-container {
    max-width: 90vw;
  }
}

.popup-container ul {
  padding-left: 1rem;
}

.popup-container button {
  background: var(--highlight);
  cursor: pointer;
  width: 100%;
  border: none;
  border-radius: 5px;
  margin-top: 1.5rem;
  padding: 1rem 2rem;
  font-size: 1rem;
  font-weight: 600;
}

.popup-container .solution {
  margin: .5rem -14px .5rem 0;
  display: block;
}

.popup-container .input {
  width: calc(25% - 14px);
  height: 32px;
  border-radius: 5px;
  margin: 0 14px 0 0;
  display: inline-block;
  position: relative;
}

.popup-container .input:after {
  content: "";
  width: 18px;
  height: 18px;
  border-radius: 50%;
  display: block;
  position: absolute;
  top: -9px;
  right: -9px;
}

.popup-container .input--1 {
  background-color: var(--input1);
}

.popup-container .input--2 {
  background-color: var(--input2);
}

.popup-container .input--3 {
  background-color: var(--input3);
}

.popup-container .input--4 {
  background-color: var(--input4);
}

.popup-container .input--5 {
  background-color: var(--input5);
}

.popup-container .input--6 {
  background-color: var(--input6);
}

.popup-container .input-count--0 {
  animation-delay: 0s;
}

.popup-container .input-count--1 {
  animation-delay: .5s;
}

.popup-container .input-count--2 {
  animation-delay: 1s;
}

.popup-container .input-count--3 {
  animation-delay: 1.5s;
}

[data-tries] {
  padding: 0 0 0 .25rem;
  font-weight: 600;
  display: inline-block;
}

@media only screen and (max-width: 768px) {
  [data-tries] {
    padding: 1rem 0;
    display: block;
  }
}

[data-share] {
  cursor: pointer;
  justify-content: center;
  align-items: center;
  transition: background-color .2s ease-in-out;
  display: flex;
}

[data-share] svg {
  margin-left: 1rem;
}

[data-share]:hover {
  background-color: var(--input5);
}

misc-buttons {
  position: absolute;
  top: 15px;
  right: 15px;
}

@media only screen and (max-width: 768px) {
  misc-buttons {
    top: 30px;
    right: 30px;
  }
}

misc-buttons button {
  opacity: .5;
  cursor: pointer;
  background-color: #0000;
  border: 0;
  transition: opacity .2s ease-in-out;
}

misc-buttons button:hover {
  opacity: 1;
}

footer {
  text-align: center;
  opacity: .3;
  padding-top: 0;
}

footer a, footer a:visited {
  color: var(--color-text);
}

:root {
  --animate-duration: 1s;
  --animate-delay: 1s;
  --animate-repeat: 1;
}

.animate__animated {
  animation-duration: 1s;
  -webkit-animation-duration: var(--animate-duration);
  animation-duration: var(--animate-duration);
  animation-fill-mode: both;
}

.animate__animated.animate__infinite {
  animation-iteration-count: infinite;
}

.animate__animated.animate__repeat-1 {
  animation-iteration-count: 1;
  -webkit-animation-iteration-count: var(--animate-repeat);
  animation-iteration-count: var(--animate-repeat);
}

.animate__animated.animate__repeat-2 {
  animation-iteration-count: 2;
  -webkit-animation-iteration-count: calc(var(--animate-repeat) * 2);
  animation-iteration-count: calc(var(--animate-repeat) * 2);
}

.animate__animated.animate__repeat-3 {
  animation-iteration-count: 3;
  -webkit-animation-iteration-count: calc(var(--animate-repeat) * 3);
  animation-iteration-count: calc(var(--animate-repeat) * 3);
}

.animate__animated.animate__delay-1s {
  animation-delay: 1s;
  -webkit-animation-delay: var(--animate-delay);
  animation-delay: var(--animate-delay);
}

.animate__animated.animate__delay-2s {
  animation-delay: 2s;
  -webkit-animation-delay: calc(var(--animate-delay) * 2);
  animation-delay: calc(var(--animate-delay) * 2);
}

.animate__animated.animate__delay-3s {
  animation-delay: 3s;
  -webkit-animation-delay: calc(var(--animate-delay) * 3);
  animation-delay: calc(var(--animate-delay) * 3);
}

.animate__animated.animate__delay-4s {
  animation-delay: 4s;
  -webkit-animation-delay: calc(var(--animate-delay) * 4);
  animation-delay: calc(var(--animate-delay) * 4);
}

.animate__animated.animate__delay-5s {
  animation-delay: 5s;
  -webkit-animation-delay: calc(var(--animate-delay) * 5);
  animation-delay: calc(var(--animate-delay) * 5);
}

.animate__animated.animate__faster {
  animation-duration: .5s;
  -webkit-animation-duration: calc(var(--animate-duration) / 2);
  animation-duration: calc(var(--animate-duration) / 2);
}

.animate__animated.animate__fast {
  animation-duration: .8s;
  -webkit-animation-duration: calc(var(--animate-duration) * .8);
  animation-duration: calc(var(--animate-duration) * .8);
}

.animate__animated.animate__slow {
  animation-duration: 2s;
  -webkit-animation-duration: calc(var(--animate-duration) * 2);
  animation-duration: calc(var(--animate-duration) * 2);
}

.animate__animated.animate__slower {
  animation-duration: 3s;
  -webkit-animation-duration: calc(var(--animate-duration) * 3);
  animation-duration: calc(var(--animate-duration) * 3);
}

@media print, (prefers-reduced-motion: reduce) {
  .animate__animated {
    transition-duration: 1ms !important;
    animation-duration: 1ms !important;
    animation-iteration-count: 1 !important;
  }

  .animate__animated[class*="Out"] {
    opacity: 0;
  }
}

@keyframes bounce {
  from, 20%, 53%, to {
    animation-timing-function: cubic-bezier(.215, .61, .355, 1);
    transform: translate3d(0, 0, 0);
  }

  40%, 43% {
    animation-timing-function: cubic-bezier(.755, .05, .855, .06);
    transform: translate3d(0, -30px, 0)scaleY(1.1);
  }

  70% {
    animation-timing-function: cubic-bezier(.755, .05, .855, .06);
    transform: translate3d(0, -15px, 0)scaleY(1.05);
  }

  80% {
    transition-timing-function: cubic-bezier(.215, .61, .355, 1);
    transform: translate3d(0, 0, 0)scaleY(.95);
  }

  90% {
    transform: translate3d(0, -4px, 0)scaleY(1.02);
  }
}

.animate__bounce {
  transform-origin: bottom;
  animation-name: bounce;
}

@keyframes flash {
  from, 50%, to {
    opacity: 1;
  }

  25%, 75% {
    opacity: 0;
  }
}

.animate__flash {
  animation-name: flash;
}

@keyframes pulse {
  from {
    transform: scale3d(1, 1, 1);
  }

  50% {
    transform: scale3d(1.05, 1.05, 1.05);
  }

  to {
    transform: scale3d(1, 1, 1);
  }
}

.animate__pulse {
  animation-name: pulse;
  animation-timing-function: ease-in-out;
}

@keyframes rubberBand {
  from {
    transform: scale3d(1, 1, 1);
  }

  30% {
    transform: scale3d(1.25, .75, 1);
  }

  40% {
    transform: scale3d(.75, 1.25, 1);
  }

  50% {
    transform: scale3d(1.15, .85, 1);
  }

  65% {
    transform: scale3d(.95, 1.05, 1);
  }

  75% {
    transform: scale3d(1.05, .95, 1);
  }

  to {
    transform: scale3d(1, 1, 1);
  }
}

.animate__rubberBand {
  animation-name: rubberBand;
}

@keyframes shakeX {
  from, to {
    transform: translate3d(0, 0, 0);
  }

  10%, 30%, 50%, 70%, 90% {
    transform: translate3d(-10px, 0, 0);
  }

  20%, 40%, 60%, 80% {
    transform: translate3d(10px, 0, 0);
  }
}

.animate__shakeX {
  animation-name: shakeX;
}

@keyframes shakeY {
  from, to {
    transform: translate3d(0, 0, 0);
  }

  10%, 30%, 50%, 70%, 90% {
    transform: translate3d(0, -10px, 0);
  }

  20%, 40%, 60%, 80% {
    transform: translate3d(0, 10px, 0);
  }
}

.animate__shakeY {
  animation-name: shakeY;
}

@keyframes headShake {
  0% {
    transform: translateX(0);
  }

  6.5% {
    transform: translateX(-6px)rotateY(-9deg);
  }

  18.5% {
    transform: translateX(5px)rotateY(7deg);
  }

  31.5% {
    transform: translateX(-3px)rotateY(-5deg);
  }

  43.5% {
    transform: translateX(2px)rotateY(3deg);
  }

  50% {
    transform: translateX(0);
  }
}

.animate__headShake {
  animation-name: headShake;
  animation-timing-function: ease-in-out;
}

@keyframes swing {
  20% {
    transform: rotate3d(0, 0, 1, 15deg);
  }

  40% {
    transform: rotate3d(0, 0, 1, -10deg);
  }

  60% {
    transform: rotate3d(0, 0, 1, 5deg);
  }

  80% {
    transform: rotate3d(0, 0, 1, -5deg);
  }

  to {
    transform: rotate3d(0, 0, 1, 0);
  }
}

.animate__swing {
  transform-origin: top;
  animation-name: swing;
}

@keyframes tada {
  from {
    transform: scale3d(1, 1, 1);
  }

  10%, 20% {
    transform: scale3d(.9, .9, .9)rotate3d(0, 0, 1, -3deg);
  }

  30%, 50%, 70%, 90% {
    transform: scale3d(1.1, 1.1, 1.1)rotate3d(0, 0, 1, 3deg);
  }

  40%, 60%, 80% {
    transform: scale3d(1.1, 1.1, 1.1)rotate3d(0, 0, 1, -3deg);
  }

  to {
    transform: scale3d(1, 1, 1);
  }
}

.animate__tada {
  animation-name: tada;
}

@keyframes wobble {
  from {
    transform: translate3d(0, 0, 0);
  }

  15% {
    transform: translate3d(-25%, 0, 0)rotate3d(0, 0, 1, -5deg);
  }

  30% {
    transform: translate3d(20%, 0, 0)rotate3d(0, 0, 1, 3deg);
  }

  45% {
    transform: translate3d(-15%, 0, 0)rotate3d(0, 0, 1, -3deg);
  }

  60% {
    transform: translate3d(10%, 0, 0)rotate3d(0, 0, 1, 2deg);
  }

  75% {
    transform: translate3d(-5%, 0, 0)rotate3d(0, 0, 1, -1deg);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

.animate__wobble {
  animation-name: wobble;
}

@keyframes jello {
  from, 11.1%, to {
    transform: translate3d(0, 0, 0);
  }

  22.2% {
    transform: skewX(-12.5deg)skewY(-12.5deg);
  }

  33.3% {
    transform: skewX(6.25deg)skewY(6.25deg);
  }

  44.4% {
    transform: skewX(-3.125deg)skewY(-3.125deg);
  }

  55.5% {
    transform: skewX(1.5625deg)skewY(1.5625deg);
  }

  66.6% {
    transform: skewX(-.78125deg)skewY(-.78125deg);
  }

  77.7% {
    transform: skewX(.390625deg)skewY(.390625deg);
  }

  88.8% {
    transform: skewX(-.195313deg)skewY(-.195313deg);
  }
}

.animate__jello {
  transform-origin: center;
  animation-name: jello;
}

@keyframes heartBeat {
  0% {
    transform: scale(1);
  }

  14% {
    transform: scale(1.3);
  }

  28% {
    transform: scale(1);
  }

  42% {
    transform: scale(1.3);
  }

  70% {
    transform: scale(1);
  }
}

.animate__heartBeat {
  animation-name: heartBeat;
  animation-duration: 1.3s;
  -webkit-animation-duration: calc(var(--animate-duration) * 1.3);
  animation-duration: calc(var(--animate-duration) * 1.3);
  animation-timing-function: ease-in-out;
}

@keyframes backInDown {
  0% {
    opacity: .7;
    transform: translateY(-1200px)scale(.7);
  }

  80% {
    opacity: .7;
    transform: translateY(0)scale(.7);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.animate__backInDown {
  animation-name: backInDown;
}

@keyframes backInLeft {
  0% {
    opacity: .7;
    transform: translateX(-2000px)scale(.7);
  }

  80% {
    opacity: .7;
    transform: translateX(0)scale(.7);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.animate__backInLeft {
  animation-name: backInLeft;
}

@keyframes backInRight {
  0% {
    opacity: .7;
    transform: translateX(2000px)scale(.7);
  }

  80% {
    opacity: .7;
    transform: translateX(0)scale(.7);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.animate__backInRight {
  animation-name: backInRight;
}

@keyframes backInUp {
  0% {
    opacity: .7;
    transform: translateY(1200px)scale(.7);
  }

  80% {
    opacity: .7;
    transform: translateY(0)scale(.7);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.animate__backInUp {
  animation-name: backInUp;
}

@keyframes backOutDown {
  0% {
    opacity: 1;
    transform: scale(1);
  }

  20% {
    opacity: .7;
    transform: translateY(0)scale(.7);
  }

  100% {
    opacity: .7;
    transform: translateY(700px)scale(.7);
  }
}

.animate__backOutDown {
  animation-name: backOutDown;
}

@keyframes backOutLeft {
  0% {
    opacity: 1;
    transform: scale(1);
  }

  20% {
    opacity: .7;
    transform: translateX(0)scale(.7);
  }

  100% {
    opacity: .7;
    transform: translateX(-2000px)scale(.7);
  }
}

.animate__backOutLeft {
  animation-name: backOutLeft;
}

@keyframes backOutRight {
  0% {
    opacity: 1;
    transform: scale(1);
  }

  20% {
    opacity: .7;
    transform: translateX(0)scale(.7);
  }

  100% {
    opacity: .7;
    transform: translateX(2000px)scale(.7);
  }
}

.animate__backOutRight {
  animation-name: backOutRight;
}

@keyframes backOutUp {
  0% {
    opacity: 1;
    transform: scale(1);
  }

  20% {
    opacity: .7;
    transform: translateY(0)scale(.7);
  }

  100% {
    opacity: .7;
    transform: translateY(-700px)scale(.7);
  }
}

.animate__backOutUp {
  animation-name: backOutUp;
}

@keyframes bounceIn {
  from, 20%, 40%, 60%, 80%, to {
    animation-timing-function: cubic-bezier(.215, .61, .355, 1);
  }

  0% {
    opacity: 0;
    transform: scale3d(.3, .3, .3);
  }

  20% {
    transform: scale3d(1.1, 1.1, 1.1);
  }

  40% {
    transform: scale3d(.9, .9, .9);
  }

  60% {
    opacity: 1;
    transform: scale3d(1.03, 1.03, 1.03);
  }

  80% {
    transform: scale3d(.97, .97, .97);
  }

  to {
    opacity: 1;
    transform: scale3d(1, 1, 1);
  }
}

.animate__bounceIn {
  animation-duration: .75s;
  -webkit-animation-duration: calc(var(--animate-duration) * .75);
  animation-duration: calc(var(--animate-duration) * .75);
  animation-name: bounceIn;
}

@keyframes bounceInDown {
  from, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(.215, .61, .355, 1);
  }

  0% {
    opacity: 0;
    transform: translate3d(0, -3000px, 0)scaleY(3);
  }

  60% {
    opacity: 1;
    transform: translate3d(0, 25px, 0)scaleY(.9);
  }

  75% {
    transform: translate3d(0, -10px, 0)scaleY(.95);
  }

  90% {
    transform: translate3d(0, 5px, 0)scaleY(.985);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

.animate__bounceInDown {
  animation-name: bounceInDown;
}

@keyframes bounceInLeft {
  from, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(.215, .61, .355, 1);
  }

  0% {
    opacity: 0;
    transform: translate3d(-3000px, 0, 0)scaleX(3);
  }

  60% {
    opacity: 1;
    transform: translate3d(25px, 0, 0)scaleX(1);
  }

  75% {
    transform: translate3d(-10px, 0, 0)scaleX(.98);
  }

  90% {
    transform: translate3d(5px, 0, 0)scaleX(.995);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

.animate__bounceInLeft {
  animation-name: bounceInLeft;
}

@keyframes bounceInRight {
  from, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(.215, .61, .355, 1);
  }

  from {
    opacity: 0;
    transform: translate3d(3000px, 0, 0)scaleX(3);
  }

  60% {
    opacity: 1;
    transform: translate3d(-25px, 0, 0)scaleX(1);
  }

  75% {
    transform: translate3d(10px, 0, 0)scaleX(.98);
  }

  90% {
    transform: translate3d(-5px, 0, 0)scaleX(.995);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

.animate__bounceInRight {
  animation-name: bounceInRight;
}

@keyframes bounceInUp {
  from, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(.215, .61, .355, 1);
  }

  from {
    opacity: 0;
    transform: translate3d(0, 3000px, 0)scaleY(5);
  }

  60% {
    opacity: 1;
    transform: translate3d(0, -20px, 0)scaleY(.9);
  }

  75% {
    transform: translate3d(0, 10px, 0)scaleY(.95);
  }

  90% {
    transform: translate3d(0, -5px, 0)scaleY(.985);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

.animate__bounceInUp {
  animation-name: bounceInUp;
}

@keyframes bounceOut {
  20% {
    transform: scale3d(.9, .9, .9);
  }

  50%, 55% {
    opacity: 1;
    transform: scale3d(1.1, 1.1, 1.1);
  }

  to {
    opacity: 0;
    transform: scale3d(.3, .3, .3);
  }
}

.animate__bounceOut {
  animation-duration: .75s;
  -webkit-animation-duration: calc(var(--animate-duration) * .75);
  animation-duration: calc(var(--animate-duration) * .75);
  animation-name: bounceOut;
}

@keyframes bounceOutDown {
  20% {
    transform: translate3d(0, 10px, 0)scaleY(.985);
  }

  40%, 45% {
    opacity: 1;
    transform: translate3d(0, -20px, 0)scaleY(.9);
  }

  to {
    opacity: 0;
    transform: translate3d(0, 2000px, 0)scaleY(3);
  }
}

.animate__bounceOutDown {
  animation-name: bounceOutDown;
}

@keyframes bounceOutLeft {
  20% {
    opacity: 1;
    transform: translate3d(20px, 0, 0)scaleX(.9);
  }

  to {
    opacity: 0;
    transform: translate3d(-2000px, 0, 0)scaleX(2);
  }
}

.animate__bounceOutLeft {
  animation-name: bounceOutLeft;
}

@keyframes bounceOutRight {
  20% {
    opacity: 1;
    transform: translate3d(-20px, 0, 0)scaleX(.9);
  }

  to {
    opacity: 0;
    transform: translate3d(2000px, 0, 0)scaleX(2);
  }
}

.animate__bounceOutRight {
  animation-name: bounceOutRight;
}

@keyframes bounceOutUp {
  20% {
    transform: translate3d(0, -10px, 0)scaleY(.985);
  }

  40%, 45% {
    opacity: 1;
    transform: translate3d(0, 20px, 0)scaleY(.9);
  }

  to {
    opacity: 0;
    transform: translate3d(0, -2000px, 0)scaleY(3);
  }
}

.animate__bounceOutUp {
  animation-name: bounceOutUp;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.animate__fadeIn {
  animation-name: fadeIn;
}

@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.animate__fadeInDown {
  animation-name: fadeInDown;
}

@keyframes fadeInDownBig {
  from {
    opacity: 0;
    transform: translate3d(0, -2000px, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.animate__fadeInDownBig {
  animation-name: fadeInDownBig;
}

@keyframes fadeInLeft {
  from {
    opacity: 0;
    transform: translate3d(-100%, 0, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.animate__fadeInLeft {
  animation-name: fadeInLeft;
}

@keyframes fadeInLeftBig {
  from {
    opacity: 0;
    transform: translate3d(-2000px, 0, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.animate__fadeInLeftBig {
  animation-name: fadeInLeftBig;
}

@keyframes fadeInRight {
  from {
    opacity: 0;
    transform: translate3d(100%, 0, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.animate__fadeInRight {
  animation-name: fadeInRight;
}

@keyframes fadeInRightBig {
  from {
    opacity: 0;
    transform: translate3d(2000px, 0, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.animate__fadeInRightBig {
  animation-name: fadeInRightBig;
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.animate__fadeInUp {
  animation-name: fadeInUp;
}

@keyframes fadeInUpBig {
  from {
    opacity: 0;
    transform: translate3d(0, 2000px, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.animate__fadeInUpBig {
  animation-name: fadeInUpBig;
}

@keyframes fadeInTopLeft {
  from {
    opacity: 0;
    transform: translate3d(-100%, -100%, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.animate__fadeInTopLeft {
  animation-name: fadeInTopLeft;
}

@keyframes fadeInTopRight {
  from {
    opacity: 0;
    transform: translate3d(100%, -100%, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.animate__fadeInTopRight {
  animation-name: fadeInTopRight;
}

@keyframes fadeInBottomLeft {
  from {
    opacity: 0;
    transform: translate3d(-100%, 100%, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.animate__fadeInBottomLeft {
  animation-name: fadeInBottomLeft;
}

@keyframes fadeInBottomRight {
  from {
    opacity: 0;
    transform: translate3d(100%, 100%, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.animate__fadeInBottomRight {
  animation-name: fadeInBottomRight;
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

.animate__fadeOut {
  animation-name: fadeOut;
}

@keyframes fadeOutDown {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }
}

.animate__fadeOutDown {
  animation-name: fadeOutDown;
}

@keyframes fadeOutDownBig {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate3d(0, 2000px, 0);
  }
}

.animate__fadeOutDownBig {
  animation-name: fadeOutDownBig;
}

@keyframes fadeOutLeft {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate3d(-100%, 0, 0);
  }
}

.animate__fadeOutLeft {
  animation-name: fadeOutLeft;
}

@keyframes fadeOutLeftBig {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate3d(-2000px, 0, 0);
  }
}

.animate__fadeOutLeftBig {
  animation-name: fadeOutLeftBig;
}

@keyframes fadeOutRight {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate3d(100%, 0, 0);
  }
}

.animate__fadeOutRight {
  animation-name: fadeOutRight;
}

@keyframes fadeOutRightBig {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate3d(2000px, 0, 0);
  }
}

.animate__fadeOutRightBig {
  animation-name: fadeOutRightBig;
}

@keyframes fadeOutUp {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }
}

.animate__fadeOutUp {
  animation-name: fadeOutUp;
}

@keyframes fadeOutUpBig {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate3d(0, -2000px, 0);
  }
}

.animate__fadeOutUpBig {
  animation-name: fadeOutUpBig;
}

@keyframes fadeOutTopLeft {
  from {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }

  to {
    opacity: 0;
    transform: translate3d(-100%, -100%, 0);
  }
}

.animate__fadeOutTopLeft {
  animation-name: fadeOutTopLeft;
}

@keyframes fadeOutTopRight {
  from {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }

  to {
    opacity: 0;
    transform: translate3d(100%, -100%, 0);
  }
}

.animate__fadeOutTopRight {
  animation-name: fadeOutTopRight;
}

@keyframes fadeOutBottomRight {
  from {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }

  to {
    opacity: 0;
    transform: translate3d(100%, 100%, 0);
  }
}

.animate__fadeOutBottomRight {
  animation-name: fadeOutBottomRight;
}

@keyframes fadeOutBottomLeft {
  from {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }

  to {
    opacity: 0;
    transform: translate3d(-100%, 100%, 0);
  }
}

.animate__fadeOutBottomLeft {
  animation-name: fadeOutBottomLeft;
}

@keyframes flip {
  from {
    animation-timing-function: ease-out;
    transform: perspective(400px)scale3d(1, 1, 1)translate3d(0, 0, 0)rotate3d(0, 1, 0, -360deg);
  }

  40% {
    animation-timing-function: ease-out;
    transform: perspective(400px)scale3d(1, 1, 1)translate3d(0, 0, 150px)rotate3d(0, 1, 0, -190deg);
  }

  50% {
    animation-timing-function: ease-in;
    transform: perspective(400px)scale3d(1, 1, 1)translate3d(0, 0, 150px)rotate3d(0, 1, 0, -170deg);
  }

  80% {
    animation-timing-function: ease-in;
    transform: perspective(400px)scale3d(.95, .95, .95)translate3d(0, 0, 0)rotate3d(0, 1, 0, 0);
  }

  to {
    animation-timing-function: ease-in;
    transform: perspective(400px)scale3d(1, 1, 1)translate3d(0, 0, 0)rotate3d(0, 1, 0, 0);
  }
}

.animate__animated.animate__flip {
  backface-visibility: visible;
  animation-name: flip;
}

@keyframes flipInX {
  from {
    opacity: 0;
    animation-timing-function: ease-in;
    transform: perspective(400px)rotate3d(1, 0, 0, 90deg);
  }

  40% {
    animation-timing-function: ease-in;
    transform: perspective(400px)rotate3d(1, 0, 0, -20deg);
  }

  60% {
    opacity: 1;
    transform: perspective(400px)rotate3d(1, 0, 0, 10deg);
  }

  80% {
    transform: perspective(400px)rotate3d(1, 0, 0, -5deg);
  }

  to {
    transform: perspective(400px);
  }
}

.animate__flipInX {
  animation-name: flipInX;
  backface-visibility: visible !important;
}

@keyframes flipInY {
  from {
    opacity: 0;
    animation-timing-function: ease-in;
    transform: perspective(400px)rotate3d(0, 1, 0, 90deg);
  }

  40% {
    animation-timing-function: ease-in;
    transform: perspective(400px)rotate3d(0, 1, 0, -20deg);
  }

  60% {
    opacity: 1;
    transform: perspective(400px)rotate3d(0, 1, 0, 10deg);
  }

  80% {
    transform: perspective(400px)rotate3d(0, 1, 0, -5deg);
  }

  to {
    transform: perspective(400px);
  }
}

.animate__flipInY {
  animation-name: flipInY;
  backface-visibility: visible !important;
}

@keyframes flipOutX {
  from {
    transform: perspective(400px);
  }

  30% {
    opacity: 1;
    transform: perspective(400px)rotate3d(1, 0, 0, -20deg);
  }

  to {
    opacity: 0;
    transform: perspective(400px)rotate3d(1, 0, 0, 90deg);
  }
}

.animate__flipOutX {
  animation-duration: .75s;
  -webkit-animation-duration: calc(var(--animate-duration) * .75);
  animation-duration: calc(var(--animate-duration) * .75);
  animation-name: flipOutX;
  backface-visibility: visible !important;
}

@keyframes flipOutY {
  from {
    transform: perspective(400px);
  }

  30% {
    opacity: 1;
    transform: perspective(400px)rotate3d(0, 1, 0, -15deg);
  }

  to {
    opacity: 0;
    transform: perspective(400px)rotate3d(0, 1, 0, 90deg);
  }
}

.animate__flipOutY {
  animation-duration: .75s;
  -webkit-animation-duration: calc(var(--animate-duration) * .75);
  animation-duration: calc(var(--animate-duration) * .75);
  animation-name: flipOutY;
  backface-visibility: visible !important;
}

@keyframes lightSpeedInRight {
  from {
    opacity: 0;
    transform: translate3d(100%, 0, 0)skewX(-30deg);
  }

  60% {
    opacity: 1;
    transform: skewX(20deg);
  }

  80% {
    transform: skewX(-5deg);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

.animate__lightSpeedInRight {
  animation-name: lightSpeedInRight;
  animation-timing-function: ease-out;
}

@keyframes lightSpeedInLeft {
  from {
    opacity: 0;
    transform: translate3d(-100%, 0, 0)skewX(30deg);
  }

  60% {
    opacity: 1;
    transform: skewX(-20deg);
  }

  80% {
    transform: skewX(5deg);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

.animate__lightSpeedInLeft {
  animation-name: lightSpeedInLeft;
  animation-timing-function: ease-out;
}

@keyframes lightSpeedOutRight {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate3d(100%, 0, 0)skewX(30deg);
  }
}

.animate__lightSpeedOutRight {
  animation-name: lightSpeedOutRight;
  animation-timing-function: ease-in;
}

@keyframes lightSpeedOutLeft {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate3d(-100%, 0, 0)skewX(-30deg);
  }
}

.animate__lightSpeedOutLeft {
  animation-name: lightSpeedOutLeft;
  animation-timing-function: ease-in;
}

@keyframes rotateIn {
  from {
    opacity: 0;
    transform: rotate3d(0, 0, 1, -200deg);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.animate__rotateIn {
  transform-origin: center;
  animation-name: rotateIn;
}

@keyframes rotateInDownLeft {
  from {
    opacity: 0;
    transform: rotate3d(0, 0, 1, -45deg);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.animate__rotateInDownLeft {
  transform-origin: 0 100%;
  animation-name: rotateInDownLeft;
}

@keyframes rotateInDownRight {
  from {
    opacity: 0;
    transform: rotate3d(0, 0, 1, 45deg);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.animate__rotateInDownRight {
  transform-origin: 100% 100%;
  animation-name: rotateInDownRight;
}

@keyframes rotateInUpLeft {
  from {
    opacity: 0;
    transform: rotate3d(0, 0, 1, 45deg);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.animate__rotateInUpLeft {
  transform-origin: 0 100%;
  animation-name: rotateInUpLeft;
}

@keyframes rotateInUpRight {
  from {
    opacity: 0;
    transform: rotate3d(0, 0, 1, -90deg);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.animate__rotateInUpRight {
  transform-origin: 100% 100%;
  animation-name: rotateInUpRight;
}

@keyframes rotateOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: rotate3d(0, 0, 1, 200deg);
  }
}

.animate__rotateOut {
  transform-origin: center;
  animation-name: rotateOut;
}

@keyframes rotateOutDownLeft {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: rotate3d(0, 0, 1, 45deg);
  }
}

.animate__rotateOutDownLeft {
  transform-origin: 0 100%;
  animation-name: rotateOutDownLeft;
}

@keyframes rotateOutDownRight {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: rotate3d(0, 0, 1, -45deg);
  }
}

.animate__rotateOutDownRight {
  transform-origin: 100% 100%;
  animation-name: rotateOutDownRight;
}

@keyframes rotateOutUpLeft {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: rotate3d(0, 0, 1, -45deg);
  }
}

.animate__rotateOutUpLeft {
  transform-origin: 0 100%;
  animation-name: rotateOutUpLeft;
}

@keyframes rotateOutUpRight {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: rotate3d(0, 0, 1, 90deg);
  }
}

.animate__rotateOutUpRight {
  transform-origin: 100% 100%;
  animation-name: rotateOutUpRight;
}

@keyframes hinge {
  0% {
    animation-timing-function: ease-in-out;
  }

  20%, 60% {
    animation-timing-function: ease-in-out;
    transform: rotate3d(0, 0, 1, 80deg);
  }

  40%, 80% {
    opacity: 1;
    animation-timing-function: ease-in-out;
    transform: rotate3d(0, 0, 1, 60deg);
  }

  to {
    opacity: 0;
    transform: translate3d(0, 700px, 0);
  }
}

.animate__hinge {
  animation-duration: 2s;
  -webkit-animation-duration: calc(var(--animate-duration) * 2);
  animation-duration: calc(var(--animate-duration) * 2);
  transform-origin: 0 0;
  animation-name: hinge;
}

@keyframes jackInTheBox {
  from {
    opacity: 0;
    transform-origin: bottom;
    transform: scale(.1)rotate(30deg);
  }

  50% {
    transform: rotate(-10deg);
  }

  70% {
    transform: rotate(3deg);
  }

  to {
    opacity: 1;
    transform: scale(1);
  }
}

.animate__jackInTheBox {
  animation-name: jackInTheBox;
}

@keyframes rollIn {
  from {
    opacity: 0;
    transform: translate3d(-100%, 0, 0)rotate3d(0, 0, 1, -120deg);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.animate__rollIn {
  animation-name: rollIn;
}

@keyframes rollOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate3d(100%, 0, 0)rotate3d(0, 0, 1, 120deg);
  }
}

.animate__rollOut {
  animation-name: rollOut;
}

@keyframes zoomIn {
  from {
    opacity: 0;
    transform: scale3d(.3, .3, .3);
  }

  50% {
    opacity: 1;
  }
}

.animate__zoomIn {
  animation-name: zoomIn;
}

@keyframes zoomInDown {
  from {
    opacity: 0;
    animation-timing-function: cubic-bezier(.55, .055, .675, .19);
    transform: scale3d(.1, .1, .1)translate3d(0, -1000px, 0);
  }

  60% {
    opacity: 1;
    animation-timing-function: cubic-bezier(.175, .885, .32, 1);
    transform: scale3d(.475, .475, .475)translate3d(0, 60px, 0);
  }
}

.animate__zoomInDown {
  animation-name: zoomInDown;
}

@keyframes zoomInLeft {
  from {
    opacity: 0;
    animation-timing-function: cubic-bezier(.55, .055, .675, .19);
    transform: scale3d(.1, .1, .1)translate3d(-1000px, 0, 0);
  }

  60% {
    opacity: 1;
    animation-timing-function: cubic-bezier(.175, .885, .32, 1);
    transform: scale3d(.475, .475, .475)translate3d(10px, 0, 0);
  }
}

.animate__zoomInLeft {
  animation-name: zoomInLeft;
}

@keyframes zoomInRight {
  from {
    opacity: 0;
    animation-timing-function: cubic-bezier(.55, .055, .675, .19);
    transform: scale3d(.1, .1, .1)translate3d(1000px, 0, 0);
  }

  60% {
    opacity: 1;
    animation-timing-function: cubic-bezier(.175, .885, .32, 1);
    transform: scale3d(.475, .475, .475)translate3d(-10px, 0, 0);
  }
}

.animate__zoomInRight {
  animation-name: zoomInRight;
}

@keyframes zoomInUp {
  from {
    opacity: 0;
    animation-timing-function: cubic-bezier(.55, .055, .675, .19);
    transform: scale3d(.1, .1, .1)translate3d(0, 1000px, 0);
  }

  60% {
    opacity: 1;
    animation-timing-function: cubic-bezier(.175, .885, .32, 1);
    transform: scale3d(.475, .475, .475)translate3d(0, -60px, 0);
  }
}

.animate__zoomInUp {
  animation-name: zoomInUp;
}

@keyframes zoomOut {
  from {
    opacity: 1;
  }

  50% {
    opacity: 0;
    transform: scale3d(.3, .3, .3);
  }

  to {
    opacity: 0;
  }
}

.animate__zoomOut {
  animation-name: zoomOut;
}

@keyframes zoomOutDown {
  40% {
    opacity: 1;
    animation-timing-function: cubic-bezier(.55, .055, .675, .19);
    transform: scale3d(.475, .475, .475)translate3d(0, -60px, 0);
  }

  to {
    opacity: 0;
    animation-timing-function: cubic-bezier(.175, .885, .32, 1);
    transform: scale3d(.1, .1, .1)translate3d(0, 2000px, 0);
  }
}

.animate__zoomOutDown {
  transform-origin: bottom;
  animation-name: zoomOutDown;
}

@keyframes zoomOutLeft {
  40% {
    opacity: 1;
    transform: scale3d(.475, .475, .475)translate3d(42px, 0, 0);
  }

  to {
    opacity: 0;
    transform: scale(.1)translate3d(-2000px, 0, 0);
  }
}

.animate__zoomOutLeft {
  transform-origin: 0;
  animation-name: zoomOutLeft;
}

@keyframes zoomOutRight {
  40% {
    opacity: 1;
    transform: scale3d(.475, .475, .475)translate3d(-42px, 0, 0);
  }

  to {
    opacity: 0;
    transform: scale(.1)translate3d(2000px, 0, 0);
  }
}

.animate__zoomOutRight {
  transform-origin: 100%;
  animation-name: zoomOutRight;
}

@keyframes zoomOutUp {
  40% {
    opacity: 1;
    animation-timing-function: cubic-bezier(.55, .055, .675, .19);
    transform: scale3d(.475, .475, .475)translate3d(0, 60px, 0);
  }

  to {
    opacity: 0;
    animation-timing-function: cubic-bezier(.175, .885, .32, 1);
    transform: scale3d(.1, .1, .1)translate3d(0, -2000px, 0);
  }
}

.animate__zoomOutUp {
  transform-origin: bottom;
  animation-name: zoomOutUp;
}

@keyframes slideInDown {
  from {
    visibility: visible;
    transform: translate3d(0, -100%, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

.animate__slideInDown {
  animation-name: slideInDown;
}

@keyframes slideInLeft {
  from {
    visibility: visible;
    transform: translate3d(-100%, 0, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

.animate__slideInLeft {
  animation-name: slideInLeft;
}

@keyframes slideInRight {
  from {
    visibility: visible;
    transform: translate3d(100%, 0, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

.animate__slideInRight {
  animation-name: slideInRight;
}

@keyframes slideInUp {
  from {
    visibility: visible;
    transform: translate3d(0, 100%, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

.animate__slideInUp {
  animation-name: slideInUp;
}

@keyframes slideOutDown {
  from {
    transform: translate3d(0, 0, 0);
  }

  to {
    visibility: hidden;
    transform: translate3d(0, 100%, 0);
  }
}

.animate__slideOutDown {
  animation-name: slideOutDown;
}

@keyframes slideOutLeft {
  from {
    transform: translate3d(0, 0, 0);
  }

  to {
    visibility: hidden;
    transform: translate3d(-100%, 0, 0);
  }
}

.animate__slideOutLeft {
  animation-name: slideOutLeft;
}

@keyframes slideOutRight {
  from {
    transform: translate3d(0, 0, 0);
  }

  to {
    visibility: hidden;
    transform: translate3d(100%, 0, 0);
  }
}

.animate__slideOutRight {
  animation-name: slideOutRight;
}

@keyframes slideOutUp {
  from {
    transform: translate3d(0, 0, 0);
  }

  to {
    visibility: hidden;
    transform: translate3d(0, -100%, 0);
  }
}

.animate__slideOutUp {
  animation-name: slideOutUp;
}

/*# sourceMappingURL=index.f620d834.css.map */
