:root {
  --bodyBG: #fff;
  --bodyFontColor: #232;
  --bodyFontSize: 16px;
  --bodyFontFamily: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen-Sans,Ubuntu,Cantarell,"Helvetica Neue",sans-serif;
  --bodyFontWeight: 400;
  --bp-small: 768px;
  --bp-medium: 1024px;
  --bp-large: 1280px;
  --line-height: 1.5;
  --linkFontColor: #284162;
  --linkFontColorHover: #0535d2;
  --highlight: rgba(0,0,0,0.05);
  --siteWidth: 300px;
  --input1: #E03C31;
  --input2: #FF7F41;
  --input3: #F7EA48;
  --input4: #2DC84D;
  --input5: #147BD1;
  --input6: #753BBD;
}

@media (prefers-color-scheme: dark) {
  :root {
    --bodyBG: #2e222f;
    --bodyFontColor: #fff;
    --highlight: rgba(255,255,255,0.05);
  }
}
