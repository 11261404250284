game-field {
  padding: 1rem 0 2.5rem;
  width: 100%;
  height: 70%;

  ul {
    list-style: none;
    font-size: 1.5rem;
    overflow-y: scroll;
    scroll-snap-type: y mandatory;
    scroll-behavior: smooth;
    -webkit-overflow-scrolling: touch;
    max-height: 65vh;

    @media @small {
      height: 100%;
      max-height: none;
    }
  }

  li {
    display: flex;
    width: 100%;
    align-items: center;
    scroll-snap-align: start;
    position: relative;
    padding-top: 15px;

    div {
      margin: 0 0 0.75rem 0;
      position: relative;
      background-color: var(--highlight);
      height: 42px;
      width: 100%;
      display: flex;
      padding: 5px 0;
      border-radius: 5px;
    }
  }

  .input {
    width: 25%;
    max-width: 66.666px;
    height: 32px;
    margin: 0 7.665px;
    border-radius: 5px;
    position: relative;

    &:after {
      content: '';
      display: block;
      width: 18px;
      height: 18px;
      border-radius: 50%;
      position: absolute;
      top: -9px;
      right: -9px;
    }
  }

  .input--1 {
    background-color: var(--input1);
  }

  .input--2 {
    background-color: var(--input2);
  }

  .input--3 {
    background-color: var(--input3);
  }

  .input--4 {
    background-color: var(--input4);
  }

  .input--5 {
    background-color: var(--input5);
  }

  .input--6 {
    background-color: var(--input6);
  }

  .icon {
    position: absolute;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.75rem;
    height: 10px;

    &:after {
      content: '';
      top: 0;
      left: -15px;
      width: 10px;
      height: 10px;
      border: 1px solid var(--highlight);
      border-radius: 50%;
      display: block;
      position: absolute;
    }
  }

  .icon--correct {
    right: 42px;

    &:after {
      background-color: #6abd6a;
    }
  }

  .icon--wrong {
    right: 12px;
    &:after {
      background-color: #ed060a;
    }
  }
}
