@import 'source/reset';
@import 'source/variables';
@import 'source/media-queries';
@import 'source/basics';

/* Global styling
----------------------------------------------- */

main {
  display: flex;
  flex-direction: column;
  padding: 0 0 1.5rem;
  max-height: 100vh;
  justify-content: space-between;

  @media @small {
    height: 90vh;
  }
}

.hidden {
  display: none;
  pointer-events: none;
}

hr {
  opacity: 0.5;
  margin: 1.25rem 0;
}


/* Your imports here
----------------------------------------------- */

@import 'source/game-field';
@import 'source/input-field';
@import 'source/popup';
@import 'source/misc-buttons';
@import 'source/footer';
