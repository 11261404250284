body {
  color: var(--bodyFontColor);
  background-color: var(--bodyBG);
  font-family: var(--bodyFontFamily);
  font-size: var(--bodyFontSize);
  font-weight: var(--bodyFontWeight);
  line-height: var(--line-height);
  height: 100vh;
  display: flex;
  flex-direction: column;
}

footer,
header,
main {
  margin: 0 auto;
  max-width: calc(var(--siteWidth) + 2rem);
  padding: 3rem 1rem;

  @media @small {
    padding: 1.5rem 1rem;
  };
}

a,
a:visited {
  color: var(--linkFontColor);

  &:hover {
    color: var(--linkFontColorHover);
  }
}

p {
  margin: 0.75rem 0;
  padding: 0;
}

button {
  font-size: 1rem;
  color: var(--bodyFontColor);
}
